<template>
  <div class="homepage-default">
    <div class="grid-wrapper">
      <banners :slidesLoaded="bannersLoaded"
               :banners="banners" />

      <div class="crossed desktop-only">
          <div class="content">Shop by Category</div>
      </div>

      <lazy-hydrate when-visible>
          <div class="shop-by-category desktop-only">
              <nuxt-link v-for="menuItem in shopByCategoryMenuItems"
                      :key="menuItem.id"
                      :to="{ name: 'categories', params: { name: menuItem.link } }">
                  <div class="category">
                      <lazy-img :height="150"
                                :width="150"
                                :lazy-src="menuItem.image"
                                :alt="menuItem.image_alternative_text || menuItem.name + ' image'"/>
                      <div class="name">{{ menuItem.name }}</div>
                  </div>
              </nuxt-link>
          </div>
      </lazy-hydrate>

      <div class="crossed">
          <div class="content">New Arrivals</div>
      </div>

      <lazy-hydrate when-visible>
          <ais-instant-search v-bind="instantSearchParams">
              <ais-configure :hitsPerPage="4"
                              :enableRules="true"
                              :ruleContexts="['all']"
                              :filters="defaultFilters" />
                  <ais-hits class="new-arrivals">
                      <template v-slot="{ items }">
                          <product-preview v-for="product in items"
                                            :key="product.objectID"
                                            :query-id="product.__queryID"
                                            :data-insights-object-id="product.objectID"
                                            :data-insights-position="product.__position"
                                            :data-insights-query-id="product.__queryID"
                                            :product="product"/>
                      </template>
                  </ais-hits>
          </ais-instant-search>
      </lazy-hydrate>

      <nuxt-link :to="{ name: 'products', query: { order: 'new_arrival' } }">
          <button class="btn medium section-button">SHOP NEW ARRIVALS</button>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
  import {
    AisConfigure,
    AisHits,
    AisInstantSearch
  } from 'vue-instantsearch'
  import Banners from '@/components/Banners'
  import LazyHydrate from 'vue-lazy-hydration'
  import LazyImg from '@/../shared/components/LazyImg'
  import ProductPreview from '@/components/ProductPreview'

  export default {
    name: 'HomepageDefault',
    components: {
      AisConfigure,
      AisHits,
      AisInstantSearch,
      Banners,
      LazyHydrate,
      LazyImg,
      ProductPreview
    },
    props: {
      shopByCategoryMenuItems: {
        type: Array,
        default: () => []
      },
      instantSearchParams: {
        type: Object,
        required: true
      },
      bannersLoaded: {
        type: Boolean,
        default: false
      },
      banners: {
        type: Array,
        default: () => []
      }
    },
    data () {
      return {
        defaultFilters: 'not_available:false'
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '@/assets/stylesheets/homepage';
</style>